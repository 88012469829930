<template>
    <div class="Part">
        <div class="input">
            <el-row style="padding: 2px">
                <el-col :span="24">
                    <el-button type="primary" @click="addSubscribe" size="mini" :disabled="btnUserState === 0">添加预约</el-button>
                    <el-button type="primary" @click="setSubscribe(true)" size="mini">个人预约设置</el-button>
                    <el-button type="primary" @click="setAddress(true)" size="mini">到访地址设置</el-button>
                    <el-button type="primary" @click="getRecord" size="mini">预约记录</el-button>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    访客姓名：<el-input v-model="visitors.cocContacts" @input="getData" size="mini" style="width:150px" clearable></el-input>
                    访客手机号：<el-input v-model="visitors.cocPhone" @input="getData" size="mini" style="width:150px" clearable></el-input>
                    员工姓名：<el-input v-model="visitors.suStaffName" @input="getData" size="mini" style="width:150px" clearable></el-input>
                    员工手机号：<el-input v-model="visitors.suContact" @input="getData" size="mini" style="width:150px" clearable></el-input>
                    员工预约状态：
                    <el-switch
                        v-model="userState"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        :active-value="1"
                        :inactive-value="0"
                        @change="userStateSwitch">
                    </el-switch>
                </el-col>
            </el-row>
        </div>
        <div style="text-align:center;">
            <el-table
                v-loading="loading"
                :data="visitors.data"
                style="width: 100%"
                highlight-current-row
                :height="tableHeight"
                @row-dblclick="dbRow">
                <el-table-column prop="cocContacts" label="访客姓名" width="140px"></el-table-column>
                <el-table-column prop="cocPhone" label="访客手机号" width="120px"></el-table-column>
                <el-table-column prop="juvcVisitTime" label="到访时间" width="150px"></el-table-column>
                <el-table-column prop="jcvaAddress" label="到访地址" width="150px" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="juvcVisitMatter" label="访问事由" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="suStaffName" label="员工姓名" width="80px"></el-table-column>
                <el-table-column prop="suContact" label="员工手机号" width="110px"></el-table-column>
                <el-table-column label="状态" width="150px">
                    <template slot-scope="scope">
                        <span v-if="scope.row.jucAdoptState === '未处理'">
                            <el-button type="primary" size="small">接收</el-button>
                            <el-button type="danger" size="small">拒绝</el-button>
                        </span>
                        <span v-else>
                            {{scope.row.jucAdoptState}}
                        </span>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
                :total="visitors.total"
                :page-size="10"
                :page-sizes="[5,10,20,40,100]"
                :current-page="visitors.currentPage"
                layout="total, sizes, prev, pager, next, jumper"
                background
                @size-change="sizeChange"
                @current-change="currentChange"
            ></el-pagination>
        </div>

        <el-dialog v-dialogDrag :visible.sync="qrCode.dialog" width="300px">
            <vue-qr :text="qrCode.text" :margin='0' :size="260" :logoSrc="qrCode.icon" :logoScale="0.2" colorLight="#fff" colorDark="#333"></vue-qr>
        </el-dialog>

        <el-dialog v-dialogDrag :visible.sync="subscribe.dialog" title="添加预约" @close="closeSubscribeDialog" width="360px">
            <el-form label-width="80px">
                <el-form-item label="接待人员" >
                    <el-input v-model="subscribe.userName" size="mini" disabled style="width:200px"></el-input>
                </el-form-item>
                <el-form-item label="访问地址" >
                    <el-select v-model="subscribe.juvcJcvaId" placeholder="选择访问地址" size="mini" style="width:200px" clearable @change="selectAddress">
                        <el-option v-for="(item, index) in address.list" :key="index" :label="item.jcvaAddress" :value="item.jcvaId"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="访问时间">
                    <el-row>
                        <el-col :span="21">
                            <el-date-picker
                                v-model="subscribe.time"
                                align="right"
                                type="date"
                                placeholder="选择日期"
                                :picker-options="pickerOptions"
                                value-format="yyyy-MM-dd"
                                size="mini"
                                style="width:200px"
                                :disabled="subscribe.juvcJcvaId === ''">
                            </el-date-picker>
                        </el-col>
                        <el-col :span="3" v-show="subscribe.time">
                            <el-popover
                                placement="top"
                                trigger="manual"
                                content="请选择时间段"
                                v-model="visible">
                                <el-button slot="reference" icon="el-icon-edit" circle size="small" @click="getAllTime"></el-button>
                            </el-popover>
                        </el-col>
                    </el-row>
                    {{subscribe.time + ' ' + subscribe.week + ' ' + subscribe.theHour}}
                </el-form-item>
                <el-form-item label="访问事由" >
                    <el-input v-model="subscribe.remark" size="mini" style="width:200px" type="textarea" :rows="4"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button @click="subscribe.dialog = false">取 消</el-button>
                <el-button type="primary" @click="saveSubscribe">保存</el-button>
            </div>
        </el-dialog>

        <el-dialog v-dialogDrag :visible.sync="timeDialog" width="900px" v-loading="loading" title="个人预约设置">
            <el-button type="primary" @click="addTimeDialog" size="mini">添加时间段</el-button>
            <el-table :data="periodOfTime" style="width: 100%" highlight-current-row>
                <el-table-column prop="juaAppointmentKey" label="时间段名称"></el-table-column>
                <el-table-column label="时间段">
                    <template slot-scope="scope">
                        {{scope.row.juaAppointmentUpper}}~{{scope.row.juaAppointmentLower}}
                    </template>
                </el-table-column>
                <el-table-column prop="juaWeek" label="开放星期"></el-table-column>
                <el-table-column>
                    <template slot="header" slot-scope="scope">
                        <el-checkbox v-model="updateTime.delivery" :checked="updateTime.delivery" @change="allTimeSwitch(scope.row)">是否开放</el-checkbox>
                    </template>
                    <template slot-scope="scope">
                        <el-switch 
                            v-model="scope.row.juaAppointmentState"
                            active-value="0"
                            inactive-value="1"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            @change="oneTimeSwitch(scope.row)">
                        </el-switch>         
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" icon="el-icon-edit" circle @click="editTimeDialog(scope.row)"></el-button>
                        <el-button type="danger" icon="el-icon-delete" circle @click="deleteTime(scope.row)"></el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>

        <el-dialog v-dialogDrag :visible.sync="updateTime.dialog" width="650px" v-loading="loading" @close="closeUpdateTimeDialog" :title="updateTime.title">
            <el-form label-width="100px" :model="updateTime" ref="updateTime">
                <el-form-item 
                    label="时间段名称" 
                    prop="juaAppointmentKey" 
                    :rules="{ required: true, message: '请输入时间段名称', trigger: 'blur' }">
                    <el-input v-model="updateTime.juaAppointmentKey" size="mini" style="width: 220px"></el-input>
                </el-form-item>
                <el-form-item 
                    label="开始时间" 
                    prop="juaAppointmentUpper" 
                    :rules="{ required: true, message: '请输入开始时间', trigger: 'change' }">
                    <el-time-select
                        size="mini"
                        v-model="updateTime.juaAppointmentUpper"
                        :picker-options="updateTime.startPickerOptions"
                        placeholder="选择时间">
                    </el-time-select>
                </el-form-item>
                <el-form-item 
                    label="结束时间" 
                    prop="juaAppointmentLower" 
                    :rules="{ required: true, message: '请输入结束时间', trigger: 'change' }">
                    <el-time-select
                        size="mini"
                        v-model="updateTime.juaAppointmentLower"
                        :picker-options="updateTime.endPickerOptions"
                        placeholder="选择时间"
                        :disabled="updateTime.startTimeIsNull">
                    </el-time-select>
                </el-form-item>
                <el-form-item 
                    label="选择星期"
                    prop="checkboxWeekes" 
                    :rules="{ required: true, message: '请选择星期', trigger: 'change' }">
                    <el-checkbox-group v-model="updateTime.checkboxWeekes" size="small">
                        <el-checkbox-button v-for="week in weekes" :label="week" :key="week">{{week}}</el-checkbox-button>
                    </el-checkbox-group>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button @click="updateTime.dialog = false">取 消</el-button>
                <el-button type="primary" @click="addTime" v-if="updateTime.title === '添加时间段'">保存</el-button>
                <el-button type="primary" @click="modifyTime" v-if="updateTime.title === '修改时间段'">保存</el-button>
            </div>
        </el-dialog>

        <el-dialog v-dialogDrag :visible.sync="address.dialog" width="900px" v-loading="loading" title="地址设置">
            <el-button type="primary" @click="addAddressShow" size="mini">添加地址</el-button>
            <el-table :data="address.list" style="width: 100%" highlight-current-row>
                <el-table-column prop="jcvaAddress" label="地址"></el-table-column>
                <el-table-column label="开放时间">
                    <template slot-scope="scope">
                        {{scope.row.jcvaStartTime}} ~ {{scope.row.jcvaEndTime}}
                    </template>
                </el-table-column>
                <el-table-column prop="jcvaWeek" label="开放星期"></el-table-column>
                <el-table-column>
                    <template slot="header" slot-scope="scope">
                        <el-checkbox v-model="address.delivery" :checked="address.delivery" @change="allAddressSwitch(scope.row)">是否开放</el-checkbox>
                    </template>
                    <template slot-scope="scope">
                        <el-switch 
                            v-model="scope.row.jcvaAppointmentState"
                            active-value="0"
                            inactive-value="1"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            @change="oneAddressSwitch(scope.row)">
                        </el-switch>         
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="修改地址" placement="top-start">
                            <el-button type="primary" icon="el-icon-edit" circle @click="editAddressDialog(scope.row)"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="删除地址" placement="top-start">
                            <el-button type="danger" icon="el-icon-delete" circle @click="deleteAddress(scope.row)"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="绑定设备" placement="top-start">
                            <el-button type="info" icon="el-icon-video-camera" circle @click="getDevice(scope.row)"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog v-dialogDrag :visible.sync="address.addAddressDialog" @close="closeAddAddressDialog" width="650px" v-loading="loading" :title="address.title">
            <el-form label-width="90px" :model="address" ref="address">
                <el-form-item 
                    label="地址" 
                    prop="jcvaAddress" 
                    :rules="{ required: true, message: '请输入地址', trigger: 'blur' }">
                    <el-input v-model="address.jcvaAddress" size="mini" style="width: 220px"></el-input>
                </el-form-item>
                <el-form-item 
                    label="时间段"
                    prop="endTime" 
                    :rules="{ required: true, message: '请选择时间段', trigger: 'change' }">
                    <el-time-select
                        placeholder="起始时间"
                        v-model="address.startTime"
                        :picker-options="{
                            start: '00:00',
                            step: '00:30',
                            end: '24:00'
                        }"
                        size="mini" style="width:150px;margin-right:10px">
                    </el-time-select>
                    <el-time-select
                        placeholder="结束时间"
                        v-model="address.endTime"
                        :picker-options="{
                            start: '00:00',
                            step: '00:30',
                            end: '24:00',
                            minTime: address.startTime
                        }"
                        :disabled="address.noStartTime"
                        size="mini" style="width:150px">
                    </el-time-select>
                </el-form-item>
                <el-form-item 
                    label="选择星期"
                    prop="checkboxWeekes" 
                    :rules="{ required: true, message: '请选择星期', trigger: 'change' }">
                    <el-checkbox-group v-model="address.checkboxWeekes" size="small">
                        <el-checkbox-button v-for="week in weekes" :label="week" :key="week">{{week}}</el-checkbox-button>
                    </el-checkbox-group>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button @click="address.addAddressDialog = false">取 消</el-button>
                <el-button type="primary" @click="addAddress" v-if="address.title === '添加地址'">保存</el-button>
                <el-button type="primary" @click="modifyAddress" v-if="address.title === '修改地址'">保存</el-button>
            </div>
        </el-dialog>
        <el-dialog v-dialogDrag :visible.sync="device.dialog" width="550px" v-loading="loading" title="绑定设备">
            <el-transfer v-model="device.bindList" :data="device.list" :titles="['未绑定', '已绑定']"></el-transfer>
            <div slot="footer">
                <el-button @click="device.dialog = false">取 消</el-button>
                <el-button type="primary" @click="addDevice">保存</el-button>
            </div>
        </el-dialog>

        <el-dialog v-dialogDrag :visible.sync="selectTimeDialog" width="800px" title="时间表">
            <el-form label-width="60px">
                <el-form-item v-for="(item, index) in timeTable" :key="index" :label="item.name">
                    <div class="time_table">
                        <div 
                            v-for="(item2, index2) in item.list" 
                            :key="index2" 
                            :class="{'ban': item2.disabled, 'selected_icon': (firstIndex === index) && (secondIndex === index2)}" 
                            @click="selectTime(index, index2, item2.disabled, item2.label)"
                            :title="visitList[item2.label]">
                            {{item2.label}} 
                            <span v-if="item2.disabled" class="none"><span v-if="visitList[item2.label]" class="has">已预约</span><span v-else>不可预约</span></span>
                            <span v-else class="can"><span v-if="visitList[item2.label]" class="has">已预约</span><span v-else>可预约</span></span>
                        </div>
                    </div>
                </el-form-item>
            </el-form>
            <div slot="footer" v-show="timeTable.length > 0">
                <el-button @click="selectTimeDialog = false">取 消</el-button>
                <el-button type="primary" @click="confirm">保存</el-button>
            </div>
            <div v-show="timeTable.length === 0" style="text-align:center">没有可用时间段</div>
        </el-dialog>

        <el-dialog v-dialogDrag :visible.sync="qrcodeRecord.dialog" @close="closeRecord" width="900px" title="预约记录">
            员工姓名：<el-input v-model="qrcodeRecord.suStaffName" @input="getRecord" size="mini" style="width:200px" clearable></el-input>
            手机号：<el-input v-model="qrcodeRecord.suContact" @input="getRecord" size="mini" style="width:200px" clearable></el-input>
            <div style="text-align:center;">
                <el-table
                    v-loading="loading"
                    :data="qrcodeRecord.list"
                    style="width: 100%"
                    highlight-current-row>
                    <el-table-column prop="suStaffName" label="发起人" width="80px"></el-table-column>
                    <el-table-column prop="suContact" label="手机号" width="130px"></el-table-column>
                    <el-table-column prop="juvcVisitTime" label="访问时间"></el-table-column>
                    <el-table-column prop="juvcVisitMatter" label="访问事由" show-overflow-tooltip></el-table-column>
                    <el-table-column label="有效时间">
                        <template slot-scope="scope">
                            {{scope.row.juvcStartTime}} ~<br>
                            {{scope.row.juvcEndTime}}
                        </template>
                    </el-table-column>
                    <el-table-column label="二维码" width="90px">
                        <template slot-scope="scope">
                            <el-button type="primary" @click="showQrCode(scope.row)" size="mini">查看</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    :total="qrcodeRecord.total"
                    :page-size="8"
                    :page-sizes="[4,8,16,32,100]"
                    :current-page="qrcodeRecord.currentPage"
                    layout="total, sizes, prev, pager, next, jumper"
                    background
                    @size-change="sizeChange2"
                    @current-change="currentChange2"
                ></el-pagination>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import moment from 'moment'
function CompareDate(t1, t2) {
    var date = new Date();
    var a = t1.split(":");
    if (t2) {
        var b = t2.split(":");
        return date.setHours(a[0],a[1]) <= date.setHours(b[0],b[1]);
    }
    var my_hours=date.getHours();
    var my_minutes=date.getMinutes();
    return date.setHours(a[0],a[1]) <= date.setHours(my_hours,my_minutes);
}
function isToday(str){
    var d = new Date(str.replace(/-/g,"/"));
    var todaysDate = new Date();
    if(d.setHours(0,0,0,0) == todaysDate.setHours(0,0,0,0)){
        return true;
    } else {
        return false;
    }
}
const d2n = {
    '星期日': 0,
    '星期一': 1,
    '星期二': 2,
    '星期三': 3,
    '星期四': 4,
    '星期五': 5,
    '星期六': 6,
}
// 星期排序
const sortByWeekday = (fst, snd) => {
    return d2n[fst] - d2n[snd]
}
export default {
    name: 'Visitors',
    data() {
        return {
            loading: false,
            visitors: { // 预约相关
                data: [],
                total: 0,
                currentPage: 0,
                limit: 10,
                cursor: 1,
                cocContacts: '',
                cocPhone: '',
                suStaffName: '',
                suContact: '',
            },
            tableHeight: window.innerHeight - 225,
            qrCode: {
                dialog: false,
                text: '',
                icon: require('../../assets/image/favicon.png')
            },
            subscribe: { // 添加预约相关
                dialog: false,
                userName: sessionStorage.getItem('userName'),
                time: '',
                remark: '',
                theHour: '',
                juvcJcvaId: '',
                week: '',
            },
            timeDialog: false, //预约设置弹窗
            periodOfTime: [], // 时间段列表
            updateTime: { // 时间段相关
                dialog: false,
                title: '',
                juaAppointmentKey: '',
                juaAppointmentUpper: '',
                juaAppointmentLower: '',
                juaId: '',
                startPickerOptions: {
                    start: '00:00',
                    step: '00:30',
                    end: '23:30'
                },
                endPickerOptions: {
                    start: '00:00',
                    step: '00:30',
                    end: '24:00'
                },
                startTimeIsNull: true,
                checkboxWeekes: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
                delivery: true,
            },
            pickerOptions: this.limitDate(),
            status: true, // 是否是点击修改时间段
            address: { // 地址设置相关
                dialog: false,
                jcvaAddress: '',
                list: [],
                addAddressDialog: false,
                title: '',
                jcvaId: '',
                checkboxWeekes: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
                startTime: '',
                endTime: '',
                noStartTime: true,
                delivery: true,
                modify: false
            },
            timeTable: [], // 时间段列表
            selectTimeDialog: false, // 时间表弹窗
            firstIndex: '', // 选择一个时间段
            secondIndex: '', // 选择一个时间段
            visible: false,
            qrcodeRecord: { // 预约记录相关
                list: [],
                dialog: false,
                total: 0,
                currentPage: 0,
                limit: 8,
                cursor: 1,
                suStaffName: '',
                suContact: '',
            },
            device: { // 设备相关
                list: [],
                bindList: [],
                dialog: false,
                jcvaId: '',
            },
            weekes: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
            addressStartTime: '',// 地址开始结束时间
            addressEndTime: '', // 地址开放结束时间
            userState: 0,   //用户预约状态开关
            btnUserState: 0, // 是否可以生成预约
            visitList: {}, // 根据日期查询的访客列表
        };
    },
    created() {
        this.getData()
        this.getUserState()
    },
    watch:{
        juaAppointmentUpper(val, oldVal){
            if (this.status) {
                this.updateTime.juaAppointmentLower = ''
                if (val) {
                    this.updateTime.endPickerOptions.start = this.computeTime(val, 'up')
                    this.updateTime.startTimeIsNull = false
                } else {
                    this.updateTime.startTimeIsNull = true
                }
            }
            this.status = true
        },
        time(val, oldVal) { // 添加预约选择访问时间
            if (val) {
                switch (moment(this.subscribe.time).day()) {
                    case 1:
                        this.subscribe.week = '星期一'
                        break;
                    case 2:
                        this.subscribe.week = '星期二'
                        break;
                    case 3:
                        this.subscribe.week = '星期三'
                        break;
                    case 4:
                        this.subscribe.week = '星期四'
                        break;
                    case 5:
                        this.subscribe.week = '星期五'
                        break;
                    case 6:
                        this.subscribe.week = '星期六'
                        break;
                    case 0:
                        this.subscribe.week = '星期日'
                        break;
                    default:
                        break;
                }
                this.visitList = {}
                //根据选择的日期查询当天有没有预约
                this.$axios({
                    method: 'GET',
                    url: '/common2/userVisitCode/getRelation',
                    params: {
                        day: this.subscribe.time
                    }
                }).then(res => {
                    for (let item of res.data.result) {
                        let date = new Date(item.juvcVisitTime)
                        let time = date.getHours() + ':' + date.getMinutes()
                        if (this.visitList[time + '~' + this.computeTime(time, 'up')]) {
                            this.visitList[time + '~' + this.computeTime(time, 'up')] += ',' + item.cocContacts
                        } else {
                            this.visitList[time + '~' + this.computeTime(time, 'up')] = item.cocContacts
                        }
                    }
                }).catch(err => {
                    this.isError(err, this)
                })
            } else {
                this.subscribe.time = ''
                this.subscribe.week = ''
            }
            this.firstIndex = ''
            this.secondIndex = ''
            this.subscribe.theHour = ''
        },
        startTime(val, oldVal) {
            // 防止点击修改时清空结束时间
            if (this.address.modify) {
                this.address.modify = false
                this.address.noStartTime = false
                return
            }
            // 选择开始时间才能选择结束时间
            this.address.noStartTime = val ? false : true
            // 开始时间发生改变时清空结束时间
            this.address.endTime = val === oldVal ? this.address.endTime : ''
        }
    },
    computed: {
        juaAppointmentUpper() {
    　　　　return this.updateTime.juaAppointmentUpper
    　　},
        time() {
            return this.subscribe.time
        },
        startTime() {
            return this.address.startTime
        }
    },
    methods: {
        limitDate(weekArr) {
            return {
                disabledDate(time) {
                    if (weekArr) { // 过滤地址不开放的星期
                        return (weekArr.indexOf(time.getDay()) === -1) || (time.getTime() < Date.now() - 8.64e7)
                    } else {
                        return time.getTime() < Date.now() - 8.64e7; // 可选范围包括今天
                    }
                },
            }
        },
        computeTime(val, type) { // 计算可选择时间段范围
            let arr = val.split(':')
            arr = arr.map((element) => {
                return Number(element)
            })
            if (arr[1] === 30) {
                type ==='up' && arr[0] ++
                arr[1] = 0
            } else {
                type ==='down' && arr[0] --
                arr[1] = 30
            }
            arr = arr.map((element) => {
                return element < 10 ? ('0' + element) : element
            })
            return arr.join(':')
        },
        getAllTime() {
            this.selectTimeDialog = true
            this.timeTable = []
            for (let item of this.periodOfTime) {
                let weekArr = item.juaWeek.split(',')
                // 过滤不开放的星期
                if (weekArr.indexOf(this.subscribe.week) === -1) {
                    continue;
                }
                let start = item.juaAppointmentUpper.split(':')
                start = start.map((element) => {
                    return Number(element)
                })
                let end = item.juaAppointmentLower.split(':')
                end = end.map((element) => {
                    return Number(element)
                })
                let arr = []
                for (let i=start[0];i<=end[0];i++) {
                    let toStr = i < 10 ? ('0' + i) : i
                    if (start[0] === i && start[1] !== 0) {
                        arr.push([toStr, '30'].join(':'))
                    } else if (end[0] === i && end[1] === 0) {
                        arr.push([toStr, '00'].join(':'))
                    } else {
                        arr.push([toStr, '00'].join(':'))
                        arr.push([toStr, '30'].join(':'))
                    }
                }
                let arr2 = [];
                
                for (let i=0;i<arr.length-1;i++) {
                    // 地址营业时间结束之后不可预约
                    if (CompareDate(this.addressEndTime, arr[i])) {
                        arr2.push({label:arr[i]+'~'+arr[i+1], disabled: true})
                        continue
                    }
                    // 地址营业时间开始之前不可预约
                    if (CompareDate(arr[i], this.addressStartTime)) {
                        arr2.push({label:arr[i]+'~'+arr[i+1], disabled: true})
                        continue
                    }
                    // 超时的时间不可预约
                    if (CompareDate(arr[i]) && isToday(this.subscribe.time)) {
                        arr2.push({label:arr[i]+'~'+arr[i+1], disabled: true})
                    } else {
                        arr2.push({label:arr[i]+'~'+arr[i+1], disabled: false})
                    }
                }
                this.timeTable.push({name: item.juaAppointmentKey, list: arr2})
            }
        },
        getUserState() { // 用户状态总开关
            this.$axios({
                method: 'get',
                url: '/common2/users',
            }).then(res => {
                this.userState = res.data.result.suAppointmentState
                this.btnUserState = this.userState
            }).catch(err => {
                this.isError(err,this)
            })
        },
        userStateSwitch() { // 改变用户状态
            this.$axios({
                method: 'PUT',
                url: '/common2/userVisitCode/user',
                params: {
                    state: this.userState.toString()
                }
            }).then(res => {
                this.getUserState()
                this.$message.success('切换成功')
            }).catch(err => {
                this.isError(err,this)
            })
        },
        getData() { // 获取外部顾客与来访码关系
            this.$axios({
                method: 'get',
                url: '/common2/userVisitCode/getPage',
                params: {
                    limit: this.visitors.limit,
                    cursor: this.visitors.cursor,
                    cocContacts: this.visitors.cocContacts,
                    cocPhone: this.visitors.cocPhone,
                    suStaffName: this.visitors.suStaffName,
                    suContact: this.visitors.suContact,
                }
            }).then(res => {
                for (let item of res.data.result.records) {
                    switch (item.jucAdoptState) {
                        case '0':
                            item.jucAdoptState = '未处理'
                            break;
                        case '1':
                            item.jucAdoptState = '未通过'
                            break;
                        case '2':
                            item.jucAdoptState = '已通过'
                            break;
                        default:
                            break;
                    }
                }
                this.visitors.data = res.data.result.records
                this.visitors.total = res.data.result.total
                this.visitors.currentPage = res.data.result.current
            }).catch(err => {
                this.isError(err,this)
            })
        },
        selectTime(index, index2, disabled, time) { // 选择一个时间段
            if (disabled) return
            [this.firstIndex, this.secondIndex, this.subscribe.theHour] = [index, index2, time]
            this.visible = false
        },
        confirm() { // 确认选择一个时间段
            this.selectTimeDialog = false
        },
        dbRow(row) {
            // console.log(row.index)
        },
        sizeChange(val) {
            this.visitors.limit = val;
            this.getData()
        },
        currentChange(val) {
            this.visitors.cursor = val
            this.getData()
        },
        addSubscribe() {
            this.subscribe.dialog = true
            this.setSubscribe(false)
            this.setAddress(false)
        },
        getAddressList() { // 获取地址列表
            this.$axios({
                method: 'get',
                url: '/common2/userVisitCode/getAddress',
            }).then(res => {
                this.subscribe.addressList = res.data.result
            }).catch(err => {
                this.isError(err, this)
            })
        },
        closeSubscribeDialog() {
            this.subscribe.time = ''
            this.subscribe.remark = ''
            this.subscribe.juvcJcvaId = ''
        },
        saveSubscribe() { // 添加预约
            if (this.subscribe.time && !this.subscribe.theHour) {
                this.visible = true
                return
            }
            if (this.address.list.length === 0) {
                this.$message.warning('没有开放的地址！')
                return
            }
            this.$axios({
                method: 'get',
                url: '/common2/userVisitCode/getUrl',
                params: {
                    juvcVisitTime: this.subscribe.time && (this.subscribe.time + ' ' + this.subscribe.theHour.slice(0, 5)),
                    juvcJcvaId: this.subscribe.juvcJcvaId,
                    juvcVisitMatter: this.subscribe.remark
                }
            }).then(res => {
                this.subscribe.dialog = false
                this.qrCode.dialog = true
                this.qrCode.text = res.data.result
                this.$message.success('添加成功')
            }).catch(err => {
                this.isError(err, this)
            }).finally(() => {
                this.loading = false
            })
        },
        setSubscribe(isShow) { // 点击预约设置，查询已设置时间段
            this.timeDialog = isShow
            this.loading = isShow
            this.$axios({
                method: 'get',
                url: '/common2/userAppointment',
                params: {
                    juaAppointmentState: isShow === false ? '0' : ''
                }
            }).then(res => {
                this.updateTime.delivery = true
                for (let item of res.data.result) {
                    if (item.juaAppointmentState === '1') {
                        this.updateTime.delivery = false
                    }
                }
                this.periodOfTime = res.data.result
            }).catch(err => {
                this.isError(err, this)
            }).finally(() => {
                this.loading = false
            })
        },
        addTimeDialog() { // 添加时间段
            if (this.periodOfTime.length > 0) {
                if (this.periodOfTime[this.periodOfTime.length-1].juaAppointmentLower === '24:00') {
                    this.$message.warning('没有可用时间段！')
                    return
                }
                this.updateTime.startPickerOptions.start = this.periodOfTime[this.periodOfTime.length-1].juaAppointmentLower
            }
            this.updateTime.dialog = true
            this.updateTime.title = '添加时间段'
        },
        editTimeDialog(row) { // 修改时间段
            this.updateTime.dialog = true
            this.updateTime.title = '修改时间段'
            this.updateTime.juaAppointmentKey = row.juaAppointmentKey
            this.updateTime.juaAppointmentUpper = row.juaAppointmentUpper
            this.updateTime.juaAppointmentLower = row.juaAppointmentLower
            this.updateTime.juaId = row.juaId
            this.updateTime.checkboxWeekes = row.juaWeek.split(',')
            this.updateTime.startTimeIsNull = false
            this.status = false
             // 获取下标
            let index = this.periodOfTime.map(item => item.juaId).indexOf(row.juaId)
            // 当编辑时间段时，计算可选择的时间段范围
            if (index === 0 && this.periodOfTime.length > 1) { // 修改第一个时间段，且已存在的时间段不止一个
                this.updateTime.startPickerOptions.end = this.computeTime(this.periodOfTime[1].juaAppointmentUpper, 'down')
                this.updateTime.endPickerOptions.end = this.periodOfTime[1].juaAppointmentUpper
            } else if (index !== 0 && this.periodOfTime.length - 1 !== index) { // 修改不是第一个时间段，也不是最后一个
                this.updateTime.startPickerOptions.start = this.periodOfTime[index - 1].juaAppointmentLower
                this.updateTime.startPickerOptions.end = this.computeTime(this.periodOfTime[index + 1].juaAppointmentUpper, 'down')
                this.updateTime.endPickerOptions.end = this.periodOfTime[index + 1].juaAppointmentUpper
            } else if (index !== 0 && this.periodOfTime.length - 1 === index) { // 修改不是第一个时间段，是最后一个时间段
                this.updateTime.startPickerOptions.start = this.periodOfTime[index - 1].juaAppointmentLower
                this.updateTime.endPickerOptions.start = this.computeTime(this.updateTime.juaAppointmentUpper, 'up')
            } else if (index === 0 && this.periodOfTime.length === 1) { // 修改唯一一个时间段
                this.updateTime.endPickerOptions.start = this.computeTime(this.updateTime.juaAppointmentUpper, 'up')
            }
        },
        addTime() { // 新增时间段
            if (this.submitForm(['updateTime'], this)) {
                this.loading = true
                this.updateTime.checkboxWeekes.sort(sortByWeekday)
                this.$axios({
                    method: 'post',
                    url: '/common2/userAppointment',
                    params: {
                        appointmentKey: this.updateTime.juaAppointmentKey,
                        appointmentLower: this.updateTime.juaAppointmentLower,
                        appointmentUpper: this.updateTime.juaAppointmentUpper,
                        week: this.updateTime.checkboxWeekes.join(',')
                    }
                }).then(res => {
                    this.updateTime.dialog = false;
                    this.setSubscribe(true)
                    this.$message({
                        type: 'success',
                        message: '保存成功!'
                    });
                }).catch(err => {
                    this.isError(err, this)
                }).finally(() => {
                    this.loading = false
                })
            }
        },
        modifyTime() { //修改时间段
            if (this.submitForm(['updateTime'], this)) {
                this.loading = true
                this.updateTime.checkboxWeekes.sort(sortByWeekday)
                this.$axios({
                    method: 'put',
                    url: '/common2/userAppointment',
                    params: {
                        appointmentKey: this.updateTime.juaAppointmentKey,
                        appointmentLower: this.updateTime.juaAppointmentLower,
                        appointmentUpper: this.updateTime.juaAppointmentUpper,
                        week: this.updateTime.checkboxWeekes.join(','),
                        juaId: this.updateTime.juaId
                    }
                }).then(res => {
                    this.updateTime.dialog = false;
                    this.setSubscribe(true)
                    this.$message({
                        type: 'success',
                        message: '保存成功!'
                    });
                }).catch(err => {
                    this.isError(err, this)
                }).finally(() => {
                    this.loading = false
                })
            }
        },
        closeUpdateTimeDialog() { //关闭修改或新增时间段弹窗
            this.updateTime = {
                dialog: false,
                title: '',
                juaAppointmentKey: '',
                juaAppointmentUpper: '',
                juaAppointmentLower: '',
                juaId: '',
                startPickerOptions: {
                    start: '00:00',
                    step: '00:30',
                    end: '23:30'
                },
                endPickerOptions: {
                    start: '00:00',
                    step: '00:30',
                    end: '24:00'
                },
                startTimeIsNull: true,
                checkboxWeekes: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'],
                delivery: true
            }
            this.resetForm(['updateTime'], this);
        },
        deleteTime(row) { // 删除时间段
            this.$confirm('确定删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => { // 确认删除
                this.$axios({
                    method: 'DELETE',
                    url: '/common2/userAppointment',
                    params: {
                        juaId: row.juaId
                    }
                }).then(res => {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.setSubscribe(true)
                }).catch(err => {
                    this.isError(err, this)
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        setAddress(isShow) { // 地址设置弹窗
            this.address.dialog = isShow
            this.loading = isShow
            this.$axios({
                method: 'get',
                url: '/common2/userVisitCode/getAddress',
                params: {
                    jcvaAddressState: '正常',
                    jcvaAppointmentState: isShow === false ? '0' : ''
                }
            }).then(res => {
                this.address.delivery = true
                for (let item of res.data.result) {
                    if (item.jcvaAppointmentState === '1') {
                        this.address.delivery = false
                    }
                }
                this.address.list = res.data.result
                if (this.address.list.length === 0) {
                    this.$message.warning('没有开放的地址！')
                }
            }).catch(err => {
                this.isError(err, this)
            }).finally(() => {
                this.loading = false
            })
        },
        addAddress() { // 添加地址
            if (this.submitForm(['address'], this)) {
                this.loading = true
                this.address.checkboxWeekes.sort(sortByWeekday)
                this.$axios({
                    method: 'post',
                    url: '/common2/userVisitCode/addAddress',
                    params: {
                        jcvaAddress: this.address.jcvaAddress,
                        jcvaWeek: this.address.checkboxWeekes.join(','),
                        jcvaStartTime: this.address.startTime,
                        jcvaEndTime: this.address.endTime
                    }
                }).then(res => {
                    this.address.addAddressDialog = false
                    this.$message({
                        type: 'success',
                        message: '保存成功!'
                    });
                    this.setAddress(true)
                }).catch(err => {
                    this.isError(err, this)
                }).finally(() => {
                    this.loading = false
                })
            }
        },
        modifyAddress() { // 修改地址
            if (this.submitForm(['address'], this)) {
                this.loading = true
                this.address.checkboxWeekes.sort(sortByWeekday)
                this.$axios({
                    method: 'PUT',
                    url: '/common2/userVisitCode/updateAddress',
                    data: {
                        jcvaAddress: this.address.jcvaAddress,
                        jcvaId: this.address.jcvaId,
                        jcvaWeek: this.address.checkboxWeekes.join(','),
                        jcvaStartTime: this.address.startTime,
                        jcvaEndTime: this.address.endTime
                    }
                }).then(res => {
                    this.address.addAddressDialog = false
                    this.$message({
                        type: 'success',
                        message: '保存成功!'
                    });
                    this.setAddress(true)
                }).catch(err => {
                    this.isError(err, this)
                }).finally(() => {
                    this.loading = false
                })
            }
        },
        deleteAddress(row) { // 删除地址
            this.$confirm('确定删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => { // 确认删除
                this.$axios({
                    method: 'PUT',
                    url: '/common2/userVisitCode/updateAddress',
                    data: {
                        jcvaId: row.jcvaId,
                        jcvaAddressState: '注销'
                    }
                }).then(res => {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.setAddress(true)
                }).catch(err => {
                    this.isError(err, this)
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        addAddressShow() { // 添加地址弹窗
            this.address.addAddressDialog = true
            this.address.title = '添加地址'
        },
        editAddressDialog(row) { // 修改地址弹窗
            this.address.addAddressDialog = true
            this.address.title = '修改地址'
            this.address.jcvaId = row.jcvaId
            this.address.jcvaAddress = row.jcvaAddress
            this.address.startTime = row.jcvaStartTime
            this.address.endTime = row.jcvaEndTime
            this.address.checkboxWeekes = row.jcvaWeek.split(',')
            this.address.modify = true
        },
        closeAddAddressDialog() { // 添加或修改地址弹窗关闭
            this.address.jcvaAddress = ''
            this.address.title = ''
            this.address.jcvaId = ''
            this.resetForm(['address'], this);
            this.address.startTime = ''
            this.address.endTime = ''
            this.address.checkboxWeekes = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日']
        },
        getRecord() { // 获取访问记录
            this.qrcodeRecord.dialog = true
            this.loading = true
            this.$axios({
                method: 'get',
                url: '/common2/userVisitCode/getCode',
                params: {
                    limit: this.qrcodeRecord.limit,
                    cursor: this.qrcodeRecord.cursor,
                    suStaffName: this.qrcodeRecord.suStaffName,
                    suContact: this.qrcodeRecord.suContact
                }
            }).then(res => {
                this.qrcodeRecord.list = res.data.result.records
                this.qrcodeRecord.total = res.data.result.total
                this.qrcodeRecord.currentPage = res.data.result.current
            }).catch(err => {
                this.isError(err, this)
            }).finally(() => {
                this.loading = false
            })
        },
        sizeChange2(val) {
            this.qrcodeRecord.limit = val;
            this.getRecord()
        },
        currentChange2(val) {
            this.qrcodeRecord.cursor = val
            this.getRecord()
        },
        showQrCode(row) { // 查看二维码
            this.qrCode.dialog = true
            this.qrCode.text = row.qrUrl
        },
        closeRecord() { // 关闭访问记录
            this.qrcodeRecord.limit = 8;
            this.qrcodeRecord.cursor = 1
        },
        getDevice(row) { // 查询所有人脸设备
            this.loading = true
            this.device.dialog = true
            this.device.list = []
            this.device.jcvaId = row.jcvaId
            this.$axios({
                method: 'get',
                url: '/common2/customerVisitAddress/getDeviceById',
            }).then(res => {
                for (let item of res.data.result) {
                    this.device.list.push({
                        key: item.id,
                        label: item.devNickname
                    })
                }
                this.getBindDevice()
            }).catch(err => {
                this.isError(err, this)
            }).finally(() => {
                this.loading = false
            })
        },
        getBindDevice() { // 绑定设备
            this.loading = true
            this.device.bindList = []
            this.$axios({
                method: 'get',
                url: '/common2/customerVisitAddress/getDeviceById',
                params: {
                    jdarAddressId: this.device.jcvaId
                }
            }).then(res => {
                for (let item of res.data.result) {
                    this.device.bindList.push(item.id)
                }
            }).catch(err => {
                this.isError(err, this)
            }).finally(() => {
                this.loading = false
            })
        },
        addDevice() {//修改人脸设备
            this.loading = true
            this.$axios({
                method: 'post',
                url: '/common2/customerVisitAddress/bindDevice',
                params: {
                    addressId: this.device.jcvaId
                },
                data: this.device.bindList
            }).then(res => {
                this.device.dialog = false
                this.$message.success('保存成功')
            }).catch(err => {
                this.isError(err, this)
            }).finally(() => {
                this.loading = false
            })
        },
        allAddressSwitch() {//批量修改地址开放预约的状态
            let arr = []
            let state = this.address.delivery ? '0' : '1'
            for (let item of this.address.list) {
                arr.push(item.jcvaId)
            }
            this.addressStateChange(arr, state)
        },
        oneAddressSwitch(row) {//单个修改地址开放预约的状态
            this.addressStateChange([row.jcvaId], row.jcvaAppointmentState)
        },
        addressStateChange(arr, state) {//修改地址预约状态
            this.loading = true
            this.$axios({
                method: 'PUT',
                url: '/common2/userVisitCode/updateAppointment',
                params: {
                    state: state
                },
                data: arr
            }).then(res => {
                this.setAddress(true)
                this.$message.success('修改成功')
            }).catch(err => {
                this.isError(err, this)
            }).finally(() => {
                this.loading = false
            })
        },
        allTimeSwitch() {//批量修改时间段开放预约的状态
            let arr = []
            let state = this.updateTime.delivery ? '0' : '1'
            for (let item of this.periodOfTime) {
                arr.push(item.juaId)
            }
            this.timeStateChange(arr, state)
        },
        oneTimeSwitch(row) {//单个修改时间段开放预约的状态
            this.timeStateChange([row.juaId], row.juaAppointmentState)
        },
        timeStateChange(arr, state) {//修改时间段开放状态
            this.loading = true
            this.$axios({
                method: 'PUT',
                url: '/common2/userAppointment/updateUserAppointment',
                params: {
                    state: state
                },
                data: arr
            }).then(res => {
                this.setSubscribe(true)
                this.$message.success('修改成功')
            }).catch(err => {
                this.isError(err, this)
            }).finally(() => {
                this.loading = false
            })
        },
        selectAddress() { // 选择地址时，筛选出该地址可选日期
            let weekArr = []
            for (let item of this.address.list) {
                if (item.jcvaId === this.subscribe.juvcJcvaId) {
                    this.addressStartTime = item.jcvaStartTime
                    this.addressEndTime = item.jcvaEndTime
                    weekArr = item.jcvaWeek.split(',')
                    //星期字符串转数字
                    weekArr = weekArr.map(element => {
                        return d2n[element]
                    });
                }
            }
            this.subscribe.time = ''
            this.pickerOptions =  this.limitDate(weekArr)
        }
    },
};
</script>

<style lang="scss" scoped>
.Part {
    .input {
        height: 80px;
    }
    .time_table {
        span {
            float: left;
        }
        div {
            padding: 5px;
            float: left;
            border: 1px solid #ccc;
            margin-right: 5px;
            margin-bottom: 5px;
            width: 80px;
            cursor: pointer;
            .none {
                color: #f56c6c;
            }
            .can {
                color: #67c23a;
            }
            .has {
                color: #e6a23c;
            }
        }
        .ban {
            cursor: not-allowed;
            background: #f5f7fa;
        }
        .selected_icon {
            background: transparent url('../../assets/emoji_z2c1a47.png') no-repeat;
            background-position: bottom right;
        }
    }
}
</style>
